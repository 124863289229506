button.menuIcon {

    color: #1565c0;
}

img.headerIcon {
    width: 160px;
    margin-left: 16PX;
    margin-right: 16px;
}

header.header {
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: fixed;
    z-index: 6;
    background-color: rgb(236, 236, 236);
    flex-direction: row;
}

.actions-wrapper{
    display: flex;
    position: relative;
    z-index: 10;
}

.edit{
    position: relative;
    z-index: 20;
}
.lg{
    margin-right: 60px;
    margin-left: 60px;
    color: black;
    font-size: larger;
    cursor: pointer;
}

.overlead {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.form {
    background-color: white;
    padding: 15px;
    min-width: 40%;
    z-index: 10;
}

div.temps{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tempsParent{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.phone-label{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PhoneInput{
    border-radius: 5px;
    padding: 5px;
    border: 1px solid;
    height: 40px;
    width: 200px;
}
.PhoneInput>input{
    background: none;
    border: none;
    height: 100%;
}
.modalCloseButton {
    float: right;
}

div.formControlType{
    width: 100%;
}

div.formControlChild{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

/* input{
    background-color: black;
} */

div.inputsParent{
    display: flex;
    margin: 10px;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    color: rgb(150, 150, 150);
    padding: 20px 0 10px 0;
}
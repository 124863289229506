.admin_Wrapper {
    overflow: hidden;
    display: flex;
    height: calc(100vh - 68px);
    margin-top: 68px;
}

.page {

    background: #FCFCFD;
    min-height: 100%;
    flex: 1;

}
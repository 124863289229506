.input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    gap: 10px;
}
.input>span{
color: var(--Blue-Gray-500, #64748B);
width: 100%;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.input>input{
    width: 100%;
    border-radius: 7px;
border: 1px solid var(--Blue-Gray-200, #E2E8F0);
background: #FFF;
display: flex;
padding: var(--spacing-xl, 16px) var(--spacing-3xl, 24px);
align-items: center;
gap: var(--spacing-xs, 4px);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
}

.importParent {
    display: flex;
    align-items: center;
}

.import {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px;
}

.importName {
    padding-left: 5px;
}

.importIcon {
    padding-right: 5px;
    color: silver;
}

button.settingsButton {
    color: #919697;
}

.cellsSelect {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
}

.searchIcon {
    display: flex;
    padding-left: 5px;
}

.cellsParentDiv {
    display: flex;
}

.selectParent {
    display: flex;
    justify-content: right;
    align-items: center;
}

@media only screen and (max-width: 750px) {
    .importParent {
        flex-direction: column;
        align-items: flex-start;
    }
}

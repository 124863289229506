button.createButton{
    display: flex;
    margin: 10px 15px 10px 10px;
}
.temps{
    display: flex;
    flex-direction: column;
    color: blue;
    line-height: 22px;
    padding: 0 10px;
}

div.contactWrapper{
    width: 100%;
}

.typesWrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.typeWrapper{
    display: flex;
}

p.typeText{
    font-size: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 300;
}

input{
    background-color: black;
}
.calculatoer-container{
    display: flex;
    overflow-x: auto;
}
.calculatoer-container>form::-webkit-scrollbar{
    width:5px;
    }

.obligation-container{
    width: 100%;
    padding: 5px;
    display:flex;
    gap:5px;
    align-items:start;
    border:1px solid rgba(128, 128, 128, 0.352);
    border-radius: 5px;
}
.row-fixer{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.row-fixer>div{
    width: 100% !important;
}
.errormsg{
    color: red;
}
.responce-container{
    overflow-y: auto;
    height: 100vh;
    max-width: 100%;
    padding: 20px;
    box-shadow: 24px;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.ar-padding-right {
    padding-right: 18px !important;
}
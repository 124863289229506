.parentDiv,
div.childGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img.authIcon {
    width: 200px;
    margin-top: 100px;
}

.childDiv {
    display: flex;
    width: 100%;
    height: 400px;
    border-radius: 2%;
}

.parentForm {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.parentForm > form {
    width: 100%;
}

.error-message{
    margin: 0 0 10px 0;
    color: #ce0000;
}

.forgotLinkDiv {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 10px 0 0 10px;
}

.loginLinkDiv {
    display: flex;
    justify-content: right;
}

button.registerButton,
button.loginButton,
button.forgotButton,
button.confirmButton {
    margin-top: 10px;
    margin-bottom: 5px;
}

.formController {
    display: flex;
    width: 100%;
}

div.parentGrid {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.forgotText {
    width: 100%;
    text-decoration: none;
    text-align: center;
    cursor: none;
    color: silver;
}
.input-email>div{
    height: 50px !important;
}
.input-password>div{
    height: 50px !important;
}
.forgote-password>div{
    height: 50px !important;
}
/* button.createButton{
    display: flex;
    margin: 10px 15px 10px 0;
}
.temps{
    display: flex;
    flex-direction: column;
    color: blue;
    line-height: 22px;
    padding: 0 10px;
}

div.contactWrapper{
    width: 100%;
}
.mainContainer
.typesWrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.typeWrapper{
    display: flex;
}

p.typeText{
    font-size: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 300;
}
.Navigation-Container{
    align-items: center;
    display: flex;
    justify-content: space-between;
}

input{
    background-color: black;
} */
@import "rsuite/dist/rsuite.css";
.mainContainer{
    width: 100%;

}
.userInfo{
    overflow: auto;
    height: 600px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.info{
    overflow: auto;
    flex-direction: column;
    display: flex;
    margin-top: 2px;
}
.navIcon{
    padding-right: 50px;
    cursor: pointer;
}
.infoContainer{
    margin-left: 16px;
    width: 100%;
    height: calc(100vh - 180px);
}

h2{
    margin-left: 16px;
}
.DateRangePicker > p {
    padding-bottom: 15px;
    color: #898989;
    font-size: small !important;
   }
.panel{
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
    max-height: calc(100vh - 200px);
}
.actionspopup{
    width: 81%;
    display: flex;
    flex-direction: column;
}
.input-Container{
    gap: 10px;
    align-items: end;
    display: flex;
    width: 100%;
    flex-direction: row;
}
.input-Container>div{
    height: 50px !important;

}
.-MuiInputBase-root-MuiOutlinedInput-root{
   width: 33%;
}
.textArea{
    width: 100%;
    margin-top: 10px;
    border-radius: 4px;
}
.button-container{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: end;
}

html[dir="rtl"] .-MuiSvgIcon-root-MuiSelect-icon{
    left: 0;
}
.custtom-panel{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 32px;
padding: 10px;
overflow: auto;
max-height: calc(100vh - 250px);
}
.custtom-panel>div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-3xl, 24px);
    align-self: stretch;

}
.info-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
     gap: 24px;
}
.input-panels{
    width: 100%;
    display: flex;
    gap: 25px;
}
.app-bar-container{
    padding: 10px;
}
.custtom-panel>div>p{
color: var(--Blue-Gray-900, #0F172A);
text-align: center;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.css-1bsaoml-MuiButtonBase-root-MuiTab-root.Mui-selected  {
    border-radius: 6px;
    color: #344054 !important;
    background: #fff;
}
.MuiTabs-indicator{
    display: none;
}
.Mui-selected{

}
.MuiTabs-flexContainer {
    padding: 5px;
}
hr{
    width: 100% !important;
}
.arabic>label{
    left: auto;
    margin-right: 20px;
}
.css-b62m3t-container{
    width: 33%;
}
.css-13cymwt-control{
    height: 100%;
}
   
.conatainer-info{
    display: flex;
    flex-wrap: wrap;
}
.items-bank{
    margin: 10px;
}
.items-bank>h3{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* text-overflow: unset; */
    max-width: 136px;
}
.rows{
    display: flex;
    align-items: center;
    margin:0
}
button:hover{
    background: none;
}
.card-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.link-file{
    text-overflow: ellipsis;
    max-width: 300px;
    text-decoration: none ;
    white-space: nowrap;
  overflow: hidden;
}
.infoConatiner{
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: end;
}
.infoItem{
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
}
.infoItem>p{
    margin-top: 0;
    margin-bottom: 0;
}
.priceinfoconstainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.priceinfoconstainer>h2{
margin-left: 0;
}
.cardIndo{
    padding-left: 20px;
    padding-right: 20px;
}
.inforows{
  flex-direction: row;
  display: flex;
  align-items: center;
   
}
.inforows>span{
    margin-left: 5px;
  text-wrap: nowrap;
}
.inforows>h4{
  text-wrap: nowrap;
}
.img-component>img{
    width: min-content;
}
.MuiCardMedia-root {
    width: auto !important;
    margin: 10px;
    margin-right: 10px;
    margin-left: 10px;
    height: 50px;
}

.lead-info{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    gap: 20px;

}
.lead-info>h2{
    margin: 0;
    color: #000000a3;
    font-size: 18px;
 
}
.row-data{
    display: flex;
    flex-direction: row;
    gap: 55px;
    align-items: center;
}
.row-data>div{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.row-data>div>p{
    margin: 0;
    color: #000000a3;
    font-size: 18px;
}
.solutions{
    gap: 10px;
    display: flex;
    flex-direction: column;
}
.solutions>p{
    margin: 0;
    color: #000000a3;
    font-size: 18px;
}
.filters{
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.selects{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.select{
    padding-right: 15px;
    width: 20%;
}
.ranges{
    padding-top: 15px;
    width: 100%;
    display: flex;
    padding-bottom: 15px;
    
}
.searchBtnContainer{
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}
.searchBtn{
    width: 149px;
    height:40px;
}
.name-range{
    padding-bottom: 10px;
}
.loading{
    height: 40px;
    width: 40px;
    padding-left: 15px;
}
.cars{
    height: calc(100vh - 64px);
    overflow-y: auto;
}
.DropDown{
    width: 100%;
    background-color: #fff;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    position: absolute;

}
.overlead {
    display: flex;
    justify-content: flex-start;
}

.overleadUser {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterName{
    display: flex;
    align-items: center;
}

.filterIcon{
    margin: 5px;
    color: silver;
}

.formUser {
    background-color: white;
    min-width: 50%;
    z-index: 10;
}

div.info {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.infoChilds {
    width: 100%;
    border-bottom: 1px solid silver;
    border-radius: 1px;
    word-spacing: 5px;
    letter-spacing: 1px;
    padding: 16px 0 16px 10px;
}

.typeDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.startTimeDiv {
    display: flex;
    align-items: center;
}

.endTimeDiv {
    display: flex;
    align-items: center;
}

form.date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

div.day {
    margin-right: 5px;
}

.campaignTypography {
    margin: 5px;
    text-decoration: overline;
}

.campaignTable {
    margin-top: 50px;
}

.campaignTitle {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

div.childParent {
    
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
}

.details {
    padding: 50px 0 50px 0;
    min-width: 100%;
    text-align: center;
    white-space: nowrap;
}

div.parentsInfo {
    width: 100%;
}

div.parentInfo {
    width: 100%;
}

.contactInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

button.addButton {
    border-radius: 50%;
}

.addContactsParent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
}

div.addContactsChild {
    width: 48%;
}

div.infoParentDiv {
    margin: 100px;
    width: 100%;
}

div.ContactTable {
    width: 100%;
    max-height: 550px;
    overflow: auto;
}

div.DocumentTable {
    width: 100%;
    max-height: 550px;
    overflow: auto;
}

.id {
    margin-left: 32px;
}

.ContactTable {
    margin-top: 30px;
}

div.carsParentDiv {
    display: flex;
    width: 50%;
}

div.carsInfo {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-top: 25px;
}

div.carInfo {
    width: 93%;
}

.carsTitle {
    display: flex;
    justify-content: left;
    padding: 10px;
}

div.userInfo {
    width: 100%;
}

.filterClients{
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px;
}

.userIcons {
    margin-top: 0px;
    display: flex;
}

.infoName {
    margin-left: 10px;
    color: #898989;
}

div.autoComplete {
    padding: 15px;
    min-width: 100px;
}

.filterUsers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px;
}

.usersSearch {
    padding: 10px;
}

.usersSecondDiv {
    display: flex;
}

.usersFirstDiv {
    display: flex;
}

@media only screen and (max-width: 1100px) {
    .filterUsers {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 680px) {
    .usersSecondDiv {
        display: flex;
        flex-direction: column;
    }

    .usersFirstDiv {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 780px) {
    .infoParentDiv {
        display: flex;
        flex-direction: column;
    }

    .carInfo {
        margin-top: 20px;
    }

    .addContactsParent {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    div.addContactsChild {
        width: 100%;
        margin-top: 20px;
    }

    div.userInfo {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    div.info {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .carsInfo {
        justify-content: center;
    }

    div.childParent {
        align-items: center;
        justify-content: center;
    }

}

.row{
    display: flex;
    align-items: center;
    flex-direction: row;
}

.overlead1{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: flex-end;
    z-index: 5;
}
.logOutForm1 {
    overflow-y: scroll;
    justify-content: start;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 60vw;
    height: calc(100vh - 113px);
    background-color: white;
    z-index: 10;
}
.LogOutPanelButtons1{
    padding-bottom: 40px;
    position: top;
    width: 100%;
    display: flex;
    justify-content: end;
}

.mainBlock{
   
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.inputs{
    display: flex;
    flex-direction: column;
}

.ag-ltr .ag-cell{
    display: flex;
}

.console{
    padding-top: 40px;
   
}

.types{
    padding-bottom: 10px!important;
}

.editor{
    padding-top: 15px;
}
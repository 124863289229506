.otp-container>div>input{
    border: 1px solid #11101067;
    border-radius: 5px;
    background: none;
    width: 50px;
    /* margin: 15px; */
    height: 50px;
    font-size: 50px;
    text-align: center;
}

.line{
    /* display: none; */
    border: 1px solid #fff;
    margin-right: 5px;
    margin-left: 5px;
    background: #fff;
    height: 2px;
    width: 26px;
}

.otp-container{
 
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 20px;
}
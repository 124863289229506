@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap');


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #E1E4EA;

    /* Scrollbar Track */
    ::-webkit-scrollbar {
        width: 3px;
        /* Width of scrollbar */
    }

    /* Scrollbar Thumb */
    ::-webkit-scrollbar-thumb {
        background: grey;
        /* Change to your desired color */
        border-radius: 4px;
        /* Round edges */
    }

    /* Scrollbar Track Background */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        
        /* Track color */
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
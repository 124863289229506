.menuOverlead {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    height: calc(100vh - 68px);    
    position: relative;
    z-index: 5;
    max-width: 280px;
    width: 100%;
    display: flex;
}

.LogOutPanelButtons {
    display: flex;
    justify-content: center;
}
.menuOverlead>div{
    width: 100%;
  
    border-radius: 0 0 10px 0;
    padding: 10px 5px;
    height: calc(100vh - 68px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
/* .menuList {
    position: fixed;
    border-radius: 0 0 10px 0;
    padding: 10px 5px;
    height: calc(100vh - 68px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
} */
.linkBtn{
display: flex;
align-items: center;
gap: var(--spacing-md, 8px);
align-self: stretch;
border-radius: 6px;
background: #EFF1F5;
}
button.menuButtons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
}

.icon {
    padding: 5px;
    color: silver;
}

.link {
    padding: 5px;
    color: black;
    white-space: nowrap;
}



.overlead {
    width: 100%;
    height: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.logOutForm {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    min-width: 400px;
    background-color: white;
    z-index: 10;
}

.logOutIcon {
    margin-bottom: 30px;
}


@media only screen and (max-width: 600px) {
    .link{
        display: none;
    }
    .menuOverlead{
        width: 42px;
    }
    div.menuList{
        width: 50px;
    }
}
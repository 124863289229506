.openFilterPanel {
    position: relative;
    cursor: pointer;
    color: silver;
    border-radius: 5px;
    transition: 0.3s;
    z-index: 5;
}

.filterPanelIcon {
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    transition: 2s;
    z-index: 2;
}

.filterPanelIcon:hover {
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    transition: 2s;
    z-index: 2;
}

.openFilterPanel:hover {
    transition: 0.3s;
}

.headFilter.visible svg.filterPanelIcon {
    visibility: visible;
}

.headFilter:hover svg.filterPanelIcon {
    visibility: visible;
}

svg.filterPanelIcon {
    visibility: hidden;
    width: 15px;
    padding: 5px;
}

.headFilter {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.closeDivFilter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.filter {
    display: flex;
}

div.filterDate {
    margin: 10px;
}

.formSearch {
    background-color: white;
    box-shadow: 0 0 3px 0.05px black;
    position: absolute;
    top: 50px;
    min-width: 150px;
    left: 30px;
    border-radius: 2px;
    z-index: 5;
}

.body {
    overflow-x: auto;
    display: flex;
    flex-direction: row-reverse;
}

.contenier {
    max-height: 450px;
}

thead {
    position: sticky;
    top: 0;
    z-index: 3;
    white-space: nowrap;
    background-color: white;
}

.autosizer {
    height: 100% !important;
    width: 100% !important;
}

.table {
    display: flex;
    flex-direction: column;
}

.dwb {
    cursor: pointer;
}

tbody > tr:hover {
    background-color: #181818;
}

table {
    table-layout: fixed;
    width: auto !important;
    min-width: 100%;
}

th,
td {
    min-width: 120px;
    max-width: 120px;
}

tr {
    overflow-anchor: none;
}

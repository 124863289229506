.create-lead-container{
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 88px);
    gap: 20px;
}
.header-text-lead{
    color: #00000082;
    margin: 0;
}

.register-step{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.register-step>h2{
    margin: 0;
    color: #00000082;
}
.dorp-down-container{
    max-width: 550px;
    display: flex;
    flex-direction: row;
    gap:20px

}
.dov-container{
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.dov-container>p{
    margin-top: 0;
}
.age-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.label{
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 133%;
}
hr{
  width: 560px;
  color: gray;
  margin: 0;
}
.Add-Button-container{
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
}
.container{
    /* display: flex; */
    gap: 35px
}
.container>p{

}
.lead-info{
    margin: 0;
}
.MuiFormControl-root>label{
    left: auto !important;
}
.MuiFormControl-fullWidth>label {
    left: 0 !important;
}
input[type="number"]::-webkit-inner-spin-button,
   .no-spinners input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
}

.MuiInputBase-input{
    padding: 8px 12px !important;
}